<template>
  <div class="debitor-details-container">
    <div class="page" v-for="(page, index) in pageGroups" :key="page">
      <DebitorDetailBlock :debitor="debitor" v-if="debitor && index === 0" />

      <div class="list-area" v-if="debitor && debitor.suretes && debitor.suretes.length">
        <Surete v-for="surete in page" :key="surete.uniqueIdentifier" :data="surete" :debitor="debitor" />
        <p class="end-of-result" v-if="index === pageGroups.length - 1">{{ $t('search.endOfResult') }}</p>
      </div>
      <div class="list-area" v-else-if="errorMessage && index === 0">
        <div class="no-result">
          <p>{{ $t('error.defaultError') }}</p>
        </div>
      </div>
      <div class="list-area" v-else-if="!errorMessage && (!debitor || !debitor.surete.length) && index === 0">
        <div class="no-result">
          <p>{{ $t('search.noResult') }}</p>
        </div>
      </div>
      <div class="caution-message" v-if="index === pageGroups.length - 1">
        <p>{{ $t('search.cautionDebitorsMessage') }}</p>
      </div>
      <p class="pagination">{{ index + 1 }}/{{ pageGroups.length }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Surete from '@/components/results/surete/Surete.vue';
import DebitorDetailBlock from '@/components/results/DebitorDetailBlock.vue';

export default {
  name: 'DebitorDetailsPrint',
  components: { Surete, DebitorDetailBlock },
  data() {
    return {
      params: this.$route.query,
      debitor: {
        address: {},
      },
      totalCount: 0,
      pageNumber: 0,
      pageSize: 50,
      errorMessage: false,
      pageGroups: [],
    };
  },
  computed: {
    ...mapGetters({
      debitorList: 'debitorList',
    }),
    categories() {
      return this.params.Categories;
    },
    subCategories() {
      return this.params.Subcategories;
    },
    endOfPage() {
      return this.debitor && this.debitor.suretes && this.debitor.suretes.length >= this.totalCount;
    },
  },
  async created() {
    await this.getSuretesForPrint();
  },
  mounted() {
    window.addEventListener('beforeprint', () => {
      this.getPages();
    });
  },
  methods: {
    async getSuretesForPrint() {
      try {
        const response = await this.$store.dispatch('getOneDebitorSuretes', {
          identifierDebitor: this.$route.params.id,
          typeSuretes: this.$route.query.Categories ? this.$route.params.Categories.split(',') : [],
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.debitor = response.debitor;
        this.totalCount = response.count;
        if (!this.endOfPage) {
          this.pageSize = this.totalCount;
          this.debitor = await this.getSuretes();
        }
        this.pageGroups = [this.debitor.suretes];
      } catch (e) {
        this.errorMessage = true;
      }
    },

    async getSuretes() {
      const response = await this.$store.dispatch('getDebitorSuretes', {
        identifierDebitor: this.$route.params.id,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        typeSuretes: this.$route.query.Categories ? this.$route.query.Categories.split(',') : [],
      });
      this.totalCount = response.count;
      return response.debitor;
    },

    getPages() {
      const a4height = 1122;
      let totalHeight = document.querySelector('.banner').clientHeight;
      this.pageGroups = [];
      let group = [];

      this.debitor.suretes.forEach((data, index) => {
        const domElement = document.querySelectorAll('.surete-card')[index];
        const height = domElement.clientHeight + 16;

        if (totalHeight + height >= a4height) {
          this.pageGroups.push(group);
          group = [];
          totalHeight = height;
        } else {
          totalHeight += height;
          group.push(data);
        }

        if (index === this.debitor.suretes.length - 1) {
          this.pageGroups.push(group);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 297mm;
  width: 210mm;
  padding: 10mm;
  overflow: visible;
  position: relative;

  .pagination {
    position: absolute;
    right: 10mm;
    bottom: 10mm;
    font-size: 12px;
  }
}

.debitor-details-container {
  .list-area {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 16px;
  }
}

.caution-message {
  display: flex;
  background-color: rgba(73, 103, 118, 0.1);
  border: 2px solid rgba(39, 55, 78, 0.3);
  padding: 21px 0 21px 24px;
  flex-direction: column;
  justify-content: space-between;
  page-break-inside: avoid;
  margin: 56px 0 0;
  width: 100%;

  p {
    color: $darkblue-color;
    font-family: $text-font;
    font-size: 10px;
    line-height: 16px;
  }
}

.no-result {
  height: 124px;
  width: 100%;
  background-color: rgba(39, 55, 78, 0.06);
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  p {
    color: $darkblue-color;
    font-family: $text-font;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
  }
}
</style>