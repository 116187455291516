<template>
  <div class="results-container" ref="view">
    <div class="page" v-for="(page, index) in pageGroups" :key="page" ref="page">
      <SearchFilters v-if="index === 0" />
      <div class="results-area">
        <div class="no-result" v-if="errorMessage && index === 0">
          <p>{{ errorMessage }}</p>
        </div>
        <div class="no-result" v-else-if="!debitorList.length && index === 0">
          <p>{{ $t('search.noResult') }}</p>
        </div>
        <div class="cards-area" v-else-if="debitorList.length">
          <div class="results-list">
            <DebitorCard v-for="debitor in page" :key="debitor.siren" :debitor="debitor" />
            <p class="end-of-result" v-if="page === pages">{{ $t('search.endOfResult') }}</p>
          </div>
        </div>
      </div>
      <div class="caution-message" v-if="index === pageGroups.length - 1">
        <p>{{ $t('search.caution-text-1') }}</p>
        <p>{{ $t('search.caution-text-2') }}</p>
      </div>
      <p class="pagination">{{ index + 1 }}/{{ pageGroups.length }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SearchFilters from '@/components/results/SearchFilters.vue';
import DebitorCard from '@/components/results/DebitorCard.vue';
import ReCaptchaMixin from '@/mixins/ReCaptcha';

export default {
  name: 'ResultsPrint',
  components: {
    SearchFilters,
    DebitorCard,
  },
  mixins: [ReCaptchaMixin],
  data() {
    return {
      query: this.$route.query,
      errorMessage: null,
      pages: 1,
      pageGroups: [],
    };
  },
  computed: {
    ...mapGetters({
      debitorList: 'debitorList',
    }),
  },
  async created() {
    if (!this.debitorList.length) {
      try {
        const token = await this.getReCaptchaToken('submit')
        await this.$store.dispatch('getDebitorList', { params: this.query, token });
      } catch (e) {
        this.errorMessage = this.$t('error.defaultError');
      }
    }
    this.pageGroups = [this.debitorList];
  },
  mounted() {
    window.addEventListener('beforeprint', () => {
      this.getPages();
    });
  },
  methods: {
    getPages() {
      const a4height = 1122;
      let totalHeight = document.querySelector('.banner').clientHeight;
      this.pageGroups = [];
      let group = [];

      this.debitorList.forEach((data, index) => {
        const domElement = document.querySelectorAll('.debitor-card')[index];
        const height = domElement.clientHeight + 16;

        if (totalHeight + height >= a4height) {
          this.pageGroups.push(group);
          group = [];
          totalHeight = height;
        } else {
          group.push(data);
          totalHeight += height;
        }

        if (index === this.debitorList.length - 1) {
          this.pageGroups.push(group);
        }
      });

      if (!this.debitorList.length) this.pageGroups = [[]];
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 297mm;
  width: 210mm;
  padding: 10mm;
  position: relative;
  overflow: visible;
  flex-shrink: 0;

  .pagination {
    position: absolute;
    right: 10mm;
    bottom: 10mm;
    font-size: 12px;
  }
}

.results-container {
  padding: 0;
  margin: 0;

  .results-area {
    padding: 0;
    height: 100%;

    .no-result {
      height: 124px;
      width: 100%;
      background-color: rgba(39, 55, 78, 0.06);
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      p {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
      }
    }

    .cards-area {
      margin-top: 32px;

      h5 {
        padding-left: 3%;
        color: $darkblue-color;
        font-family: $title-font;
        font-size: 16px;
        font-weight: bold;
        line-height: 17px;
      }

      .results-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        margin-top: 24px;
      }
    }
  }

  .caution-message {
    height: 106px;
    background-color: rgba(73, 103, 118, 0.1);
    border: 2px solid rgba(39, 55, 78, 0.3);
    padding: 21px 0 21px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    margin: 56px 0 0;
    page-break-inside: avoid;

    p {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 10px;
      line-height: 16px;
    }
  }
}
</style>
