<template>
  <div class="debitor-details-container">
    <DebitorDetailBlock v-if="debitor" :debitor="debitor" />
    <div class="list-area" v-if="debitor && debitor.suretes && debitor.suretes.length">
      <Surete v-for="surete in debitor.suretes" :key="surete.uniqueIdentifier" :data="surete" :debitor="debitor" />
      <div class="loader-zone" v-if="paginationSearch">
        <p>{{ $t('search.paginationLoading') }}</p>
        <Loader />
      </div>
      <p class="end-of-result" v-if="endOfPage">{{ $t('search.endOfResult') }}</p>
    </div>
    <div class="list-area" v-else-if="errorMessage">
      <div class="no-result">
        <p>{{ $t('error.defaultError') }}</p>
      </div>
    </div>
    <div class="list-area" v-else-if="isLoading">
      <div class="no-result">
        <p>{{ $t('search.loadingResult') }}</p>
      </div>
    </div>
    <div class="list-area" v-else>
      <div class="no-result">
        <p>{{ $t('search.noResult') }}</p>
      </div>
    </div>
    <div class="caution-message">
      <p>{{ $t('search.caution-text-1') }}</p>
      <p>{{ $t('search.caution-text-2') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Surete from '@/components/results/surete/Surete.vue';
import DebitorDetailBlock from '@/components/results/DebitorDetailBlock.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'DebitorDetails',
  components: { Surete, DebitorDetailBlock, Loader },
  data() {
    return {
      isLoading: true,
      params: this.$route.query,
      debitor: null,
      totalCount: 0,
      pageNumber: 0,
      pageSize: 50,
      searching: false,
      errorMessage: false,
      paginationSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      debitorList: 'debitorList',
    }),
    categories() {
      return this.params.Categories;
    },
    subCategories() {
      return this.params.Subcategories;
    },
    endOfPage() {
      return this.debitor && this.debitor.suretes && this.debitor.suretes.length >= this.totalCount;
    },
  },
  async created() {
    await this.getSuretes();
    this.isLoading = false;
  },
  mounted() {
    window.addEventListener('scroll', this.pagination);
  },
  methods: {
    async pagination() {
      const footerHeight = document.querySelector('footer').clientHeight;
      const appHeight = document.getElementById('app').clientHeight - footerHeight;
      const bottomOfSuretesList = appHeight - window.scrollY;
      const paginationLimit = window.innerHeight - footerHeight + 300;

      if (bottomOfSuretesList <= paginationLimit && !this.searching && !this.errorMessage && !this.endOfPage) {
        this.paginationSearch = true;
        this.pageNumber += 1;
        const newSuretes = await this.getSuretes();
        if (newSuretes) this.debitor.suretes = this.debitor.suretes.concat(newSuretes);
      }
    },
    async getSuretes() {
      this.searching = true;
      const response = await this.$store.dispatch('getDebitorSuretes', {
        identifierDebitor: this.$route.params.id,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        typeSuretes: this.$route.query.Categories ? this.$route.query.Categories.split(',') : [],
      });
      this.totalCount = response.count;
      if (!this.debitor) this.debitor = response.debitor;
      this.searching = false;
      this.paginationSearch = false;
      return response.debitor?.suretes;
    },
  },
  unmounted() {
    window.removeEventListener('scroll', this.pagination);
  },
};
</script>

<style lang="scss" scoped>
.debitor-details-container {
  .list-area {
    padding: 32px 14%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 16px;

    .loader-zone {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;
      font-weight: bold;
      opacity: 0.8;
    }
  }
}

.caution-message {
  display: flex;
  width: 40%;
  margin: 56px 14% 131px 14%;
  background-color: rgba(73, 103, 118, 0.1);
  border: 2px solid rgba(39, 55, 78, 0.3);
  padding: 21px 0 21px 24px;
  flex-direction: column;
  justify-content: space-between;

  p {
    color: $darkblue-color;
    font-family: $text-font;
    font-size: 10px;
    line-height: 16px;
  }
}

.no-result {
  height: 124px;
  width: 100%;
  background-color: rgba(39, 55, 78, 0.06);
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  p {
    color: $darkblue-color;
    font-family: $text-font;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .debitor-details-container {
    .list-area {
      padding: 32px 24px;
    }
  }

  .caution-message {
    height: unset;
    width: unset;
    margin: 56px 24px;
    padding: 21px;
  }
}
</style>