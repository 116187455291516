<template>
  <div class="no-siren-form">
    <div class="section">
      <div class="line">
        <h3 class="group-title">{{ $t('formulaire.informations') }}</h3>
      </div>
      <div class="line space">
        <div class="radio half" @click="type = 'company'">
          <input type="radio" id="company-radio" name="type" value="company" :checked="type === 'company'" />
          <label for="company-radio">{{ $t('formulaire.personneMorale') }}</label>
        </div>
        <div class="radio half" @click="type = 'person'">
          <input type="radio" id="person-radio" name="type" value="person" :checked="type === 'person'" />
          <label for="person-radio">{{ $t('formulaire.personnePhysiqueNonCommercante') }}</label>
        </div>
      </div>
      <div class="line">
        <div class="half">
          <InputField v-model="bindDenominationOrName" :placeholder="`${getDenominationOrLastName}*`" />
        </div>
        <div class="half relative">
          <InputField v-model="legalStatusSearch" @change="deleteLegalStatus" :placeholder="$t('formulaire.legalForm')"
            v-if="isCompany" />
          <div class="legal-list-container">
            <p v-for="form in filteredLegalList" @click="selectLegalStatus(form.name, form.code)" :key="form.code">
              {{ form.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="line">
        <h3 class="group-title">
          {{ $t('formulaire.safetyCategory') }}
        </h3>
      </div>
      <DropdownInput :options="mainCategories" :chosenValues="categoryList" @updateList="updateList" />
    </div>
    <div class="section" v-if="categoryList.includes('GD')">
      <div class="line">
        <h3 class="group-title">
          {{ $t('categories.GageSansDepossession') }}
        </h3>
      </div>
      <DropdownInput :options="nonPossessoryPledges" :chosenValues="nonPossessoryPledgesList"
        @updateList="updateNonPossessoryPledgesList" />
    </div>
    <div class="section" v-if="categoryList.includes('HM')">
      <div class="line">
        <h3 class="group-title">
          {{ $t('categories.HypothequesMaritimes') }}
        </h3>
      </div>
      <InputField v-model="debitor.ShipRegistrationNumber" :placeholder="$t('formulaire.shipIdNumber')" />
    </div>
    <div class="section" v-if="categoryList.includes('HF')">
      <div class="line">
        <h3 class="group-title">
          {{ $t('categories.HypothequesFluviales') }}
        </h3>
      </div>
      <InputField v-model="debitor.BoatRegistrationNumber" :placeholder="$t('formulaire.boatIdNumber')" />
    </div>
    <div class="section">
      <div class="button-container">
        <button class="primary contained" :disabled="!isFormValid" @click="getDebitors">
          <SearchIcon class="icon" />
          <span>{{ $t('rechercher') }}</span>
        </button>
      </div>
      <div class="caution-message">
        {{ $t('formulaire.cautionMessage') }}
        <br />
        <br />
        {{ $t('formulaire.cautionWarrantsMessage') }}
      </div>
      <div class="caution-message error" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import InputField from '@/components/inputs/InputField.vue';
import SearchIcon from '@/assets/icons/search.svg';

import { mainCategories, nonPossessoryPledges } from '@/utils/infoEnum';
import DropdownInput from '@/components/inputs/DropdownInput.vue';
import { mapGetters } from 'vuex';
import ReCaptchaMixin from '@/mixins/ReCaptcha';

export default {
  name: 'NoSirenForm',
  components: { InputField, SearchIcon, DropdownInput },
  mixins: [ReCaptchaMixin],
  data() {
    return {
      type: 'company',
      address: false,
      cantBeSent: true,
      mainCategories,
      nonPossessoryPledges,
      errorMessage: null,
      legalStatusSearch: '',
      debitor: {
        nom: '',
        legalStatus: '',
        denomination: '',
        ShipRegistrationNumber: '',
        BoatRegistrationNumber: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      categoryList: 'categoryList',
      nonPossessoryPledgesList: 'nonPossessoryPledgesList',
      legalStatusList: 'legalStatusList',
    }),
    filteredLegalList() {
      if (!this.debitor.legalStatus) {
        return this.legalStatusSearch
          ? this.legalStatusList?.filter((item) =>
            item.name.toLowerCase().includes(this.legalStatusSearch.toLowerCase())
          )
          : [];
      }

      return [];
    },
    isFormValid() {
      return (this.isCompany && this.debitor.denomination) || (!this.isCompany && this.debitor.nom);
    },
    getDenominationOrLastName() {
      return this.type === 'company' ? this.$t('formulaire.denomination') : this.$t('usedLastName');
    },
    isCompany() {
      return this.type === 'company';
    },
    bindDenominationOrName: {
      get() {
        return this.type === 'company' ? this.debitor.denomination : this.debitor.nom;
      },
      set(val) {
        if (this.type === 'company') this.debitor.denomination = val;
        else this.debitor.nom = val;
      },
    },
  },
  methods: {
    async getDebitors() {
      if (!this.isFormValid) return;

      if (this.categoryList.length) this.debitor.Categories = this.categoryList.join(',');

      if (this.categoryList.includes('GD') && this.nonPossessoryPledgesList.length) {
        this.debitor.Subcategories = this.nonPossessoryPledgesList.join(',');
      }
      if (this.categoryList.includes('HM')) {
        this.debitor.ShipRegistrationNumber = this.debitorData.ShipRegistrationNumber;
      }
      if (this.categoryList.includes('HF')) {
        this.debitor.BoatRegistrationNumber = this.debitorData.BoatRegistrationNumber;
      }
      this.debitor.typesSuretes = this.categoryList.concat(this.nonPossessoryPledgesList);
      try {
        const token = await this.getReCaptchaToken('submit')
        await this.$store.dispatch('getDebitorList', { params:this.debitor, token });
        this.$router.push({ name: 'Debitors', query: this.debitor });
      } catch (e) {
        this.errorMessage = this.$t('error.defaultError');
      }
    },
    updateList(option) {
      if (this.categoryList.includes(option)) this.$store.dispatch('removeCategory', option);
      else if (!option) this.$store.dispatch('clearCategoryList');
      else this.$store.dispatch('addCategory', option);

      if (this.categoryList.includes('HM') || this.categoryList.includes('GD')) {
        const scroller = document.querySelector('.home .form-container');

        gsap.to(document.querySelector('.home .form-container'), {
          scrollTo: { y: scroller.clientHeight },
          duration: 3,
        });
      } else {
        gsap.to(document.querySelector('.home .form-container'), {
          scrollTo: { y: 0 },
          duration: 0.3,
        });
      }
    },
    updateNonPossessoryPledgesList(option) {
      if (this.nonPossessoryPledgesList.includes(option)) {
        this.$store.dispatch('removeNonPossessoryPledgeCategory', option);
      } else if (!option) this.$store.dispatch('clearNonPossessoryPledgeCategory');
      else this.$store.dispatch('addNonPossessoryPledgeCategory', option);
    },
    selectLegalStatus(legalStatusName, legalCode) {
      this.debitor.legalStatus = legalCode;
      this.legalStatusSearch = legalStatusName;
    },
    deleteLegalStatus() {
      this.debitor.legalStatus = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.no-siren-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;

    .line {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      label {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 12px;
        line-height: 13px;
        margin-top: -10px;
        height: 17px;
        width: 100%;
      }

      .group-title {
        color: $darkblue-color;
        font-size: 16px;
        font-weight: bold;
        line-height: 17px;
        margin: 8px 0;
      }

      .adress-switch {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        user-select: none;
        text-decoration: underline;
        cursor: pointer;
        transition: 0.2s;

        &.on {
          color: $focus-color;
        }

        &.off {
          color: $red-color;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .all-category {
      height: 42px;
      width: 100%;
      border: 2px solid #caced3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 16px;

      span {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 12px;
        font-weight: bold;
        line-height: 13px;
      }
    }

    .button-container {
      height: 40px;
      width: 168px;
    }

    .caution-message {
      width: 100%;
      border: 2px solid rgba(39, 55, 78, 0.3);
      background-color: rgba(73, 103, 118, 0.1);
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 15px;
      padding: 10px;
      margin-bottom: 20px;

      &.error {
        color: $red-color;
        border-color: $red-color;
        background-color: rgba($red-color, 0.05);
      }
    }
  }
}

.button-text {
  text-transform: capitalize;
}

.space {
  justify-content: space-between;
}

.radio {
  margin-left: 0;
}

.full {
  width: 100%;
}

.half {
  width: 50%;

  &.relative {
    position: relative;
  }
}

.quarter {
  width: 25%;
}

.three-quarter {
  width: 75%;
}

.legal-list-container {
  background-color: #eef1f3;
  position: absolute;
  z-index: 20;
  top: 30px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 60px -30px;

  >p {
    padding: 0.6rem;
    cursor: pointer;
    font-size: 0.5rem;

    &:hover {
      background-color: $darkblue-color;
      color: #eef1f3;
    }
  }

  >p:first-child,
  >p:last-child {
    padding: 1.2rem 0.6rem;
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .no-siren-form {
    .section {
      .line {
        input {
          height: 45px;
        }
      }
    }
  }
}
</style>
