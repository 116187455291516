import * as types from './mutations';
import qs from 'qs';

export const actions = {
  async getDebitorList({ commit }, { params, token = null } ) {
    const availableQueries = ['siren', 'denomination', 'legalStatus', 'nom', 'typesSuretes'];
    const query = Object.entries(params)
      .filter((value) => value[1] && availableQueries.find((v) => v === value[0]))
      .map((value) => `${value[0]}=${value[1]}`)
      .join('&');
    try {

      const { companies } = await this.$axios.$get(`lookups/companies?${query}`, {
        headers: {
          'X-Challenge': token ? token: undefined
        }
      });

      commit(types.SET_LIST, companies);
      if (localStorage.getItem('PRSMdebitorQueries')) localStorage.removeItem('PRSMdebitorQueries');
      localStorage.setItem('PRSMdebitorQueries', JSON.stringify(params));
    } catch (e) {
      commit(types.SET_LIST, []);
      throw e;
    }
  },

  async getDebitorSuretes(_, { identifierDebitor, pageNumber, pageSize, typeSuretes }) {
    try {
      const response = await this.$axios.get('search', {
        params: {
          IdentifierDebitor: identifierDebitor,
          TypeSuretes: typeSuretes,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
          'x-pagination-page-number': pageNumber,
          'x-pagination-page-size': pageSize,
        },
      });
      return {
        count: Number(response.headers['x-pagination-count']),
        debitor: response.data.debiteurs[0],
      };
    } catch (e) {
      return e;
    }
  },

  async getOneDebitorSuretes(_, { identifierDebitor, pageNumber, pageSize, typeSuretes }) {
    const response = await this.$axios.get('search', {
      params: {
        IdentifierDebitor: identifierDebitor,
        TypeSuretes: typeSuretes,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      headers: {
        'x-pagination-page-number': pageNumber,
        'x-pagination-page-size': pageSize,
      },
    });
    return {
      count: Number(response.headers['x-pagination-count']),
      debitor: response.data.debiteurs[0],
    };
  },

  addCategory({ commit }, category) {
    commit(types.ADD_CATEGORY, category);
  },

  removeCategory({ commit }, category) {
    commit(types.REMOVE_CATEGORY, category);
  },

  clearCategoryList({ commit }) {
    commit(types.CLEAR_CATEGORY);
  },

  addNonPossessoryPledgeCategory({ commit }, category) {
    commit(types.ADD_NON_POSSESSORY_PLEDGE, category);
  },

  removeNonPossessoryPledgeCategory({ commit }, category) {
    commit(types.REMOVE_NON_POSSESSORY_PLEDGE, category);
  },

  clearNonPossessoryPledgeCategory({ commit }) {
    commit(types.CLEAR_NON_POSSESSORY_PLEDGE);
  },

  addShipCategory({ commit }, category) {
    commit(types.ADD_SHIP_CATEGORY, category);
  },

  removeShipCategory({ commit }, category) {
    commit(types.REMOVE_SHIP_CATEGORY, category);
  },

  clearShipCategory({ commit }) {
    commit(types.CLEAR_SHIP_CATEGORY);
  },

  addBoatCategory({ commit }, category) {
    commit(types.ADD_BOAT_CATEGORY, category);
  },

  removeBoatCategory({ commit }, category) {
    commit(types.REMOVE_BOAT_CATEGORY, category);
  },

  clearBoatCategory({ commit }) {
    commit(types.CLEAR_BOAT_CATEGORY);
  },
  async printDebitors(_, { params, fileName }) {
    const availableQueries = ['siren', 'denomination', 'legalStatus', 'nom', 'typesSuretes'];
    const query = Object.entries(params)
      .filter((value) => value[1] && availableQueries.find((v) => v === value[0]))
      .map((value) => `${value[0]}=${value[1]}`)
      .join('&');

    const blob = await this.$axios.$get(`lookups/companies/print?${query}`, { responseType: 'blob' });
    return new File([blob], fileName);
  },
  async printDebitorSuretes(_, { identifierDebitor, fileName }) {
    const blob = await this.$axios.$get(`search/print?IdentifierDebitor=${identifierDebitor}`, {
      responseType: 'blob',
    });
    return new File([blob], fileName);
  },
  async printSurete(_, { identifierDebitor, identifierSurete, fileName }) {
    const blob = await this.$axios.$get(
      `search/print?IdentifierDebitor=${identifierDebitor}&IdentifierSurete=${identifierSurete}`,
      { responseType: 'blob' }
    );
    return new File([blob], fileName);
  },
  async getOneSurete(_, { identifierDebitor, identifierSurete }) {
    const { debiteurs } = await this.$axios.$get(
      `search?IdentifierDebitor=${identifierDebitor}&IdentifierSurete=${identifierSurete}`
    );
    return debiteurs[0];
  },
  async getGreffeInformation(_, { greffeCode }) {
    const { greffe } = await this.$axios.$get(`lookups/greffes/${greffeCode}`);
    return greffe;
  },

  setdebitorOpenCardId({ commit }, cardId) {
    commit(types.SET_DEBITOR_CARD, cardId);
  },

  async getLegalStatusList({ commit }) {
    try {
      const response = await this.$axios.$get(`lookups/legalstatus`);
      commit('SET_LEGAL_STATUS_LIST', response.legalStatus);
    } catch (e) {
      return e;
    }
  },

  async getIfgRedirecturl(_, { siren }) {
    const { redirectUrl } = await this.$axios.$get(`lookups/companies/ifgredirecturl?siren=${siren}`);
    return redirectUrl;
  },
};
